<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      :show-save="!readonly"
      :show-sancel="!readonly"
      v-on="$listeners"
      no-history
      :show-delete="userIsMasterAdmin"
      :delete-document="deleteDocument"
      :special-actions="specialActions"
    />
    <document-in-tab>
      <cot-form
        v-model="isFormValide"
        ref="form"
        :disabled="readonly"
      >
        <div class="identification-logo ">
          <identification
            class="a "
            v-model="computedIdentification"
            :default-for-import="dataIsDefaultForImport"
          />
          <logo
            class="b"
            v-model="computedLogo"
          />
        </div>
        <email
          :is-company="computedIsCompany"
          v-model="computedEmailSettings"
          class="my-3"
        />
        <sms
          :is-company="computedIsCompany"
          v-model="computedSmsSettings"
          class="my-3"
        />
        <stripe
          v-if="computedStripePaymentIsActive && computedIsCompany"
          class="my-3"
          :settings="dataSettings"
          :company-id="companyId()"
          :is-stripe-connected="computedIsStripeConnected"
        />
        <letter-dematerialized
          :is-company="computedIsCompany"
          v-model="computedLetterDematerializedSettings"
          class="my-3"
        />
        <maileva-letter
          :is-company="computedIsCompany"
          v-model="computedMailevaSettings"
          class="my-3"
        />
        <custom-fields
          v-if="computedCustomFields"
          class="my-3"
          v-model="computedCustomFields"
        />
        <division-address
          class="my-3"
          v-model="computedAddress"
        />
      </cot-form>
    </document-in-tab>
    <v-dialog
      v-model="showCascadeDeleteConfirm"
      max-width=500
    >
      <v-card>
        <v-card-title>{{$t('t.Warning')}}</v-card-title>
        <v-card-text>
          <span>{{$t('t.ConfirmDeleteAllRelatedDataToDivision')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="cascadeDeleteRunning"
            @click="doCascadeDelete()"
          >{{$t('t.Ok')}}</v-btn>
          <v-btn
            color="primary"
            @click="showCascadeDeleteConfirm = false"
          >{{$t('t.Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DocEdit from '@/mixins/document-editor'

export default {
  mixins: [DocEdit],
  components: {
    CotForm: () => import('@/components/cot-form'),
    CustomFields: () => import('./custom-fields'),
    DivisionAddress: () => import('./address'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Email: () => import('./email'),
    Identification: () => import('./identification'),
    LetterDematerialized: () => import('./letter-dematerialized'),
    Logo: () => import('./logo'),
    MailevaLetter: () => import('./maileva-letter'),
    Sms: () => import('./sms'),
    Stripe: () => import('./stripe')
  },
  computed: {
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    specialActions () {
      if (this.documentIsNew || !this.userIsMasterAdmin) { return undefined }
      return [{ icon: this.$icon('i.Delete'), text: this.$t('t.DeleteAllRelatedDataToDivision'), callback: this.confirmDeleteCascade }]
    },
    computedStripePaymentIsActive () {
      return this.dataSettings?.stripePaymentIsActive
    },
    computedIsStripeConnected () {
      return this.document?.isStripeConnected
    },
    computedIsCompany () {
      return this.document?.isCompany
    },
    computedImportKey () {
      return this.document?.importKey
    },
    computedLogo: {
      get () {
        return {
          logoFileId: this.document?.logoFileId,
          logoFile: this.dataLogoFile
        }
      },
      set (v) {
        this.dataLogoFile = v?.logoFile
        this.document = Object.assign({}, this.document, { logoFileId: null })
      }
    },
    computedAddress: {
      get () {
        return this.document?.address || {}
      },
      set (v) {
        this.document = Object.assign({}, this.document, { address: v })
      }
    },
    computedLetterDematerializedSettings: {
      get () {
        return {
          ar24ProviderId: this.document?.ar24ProviderId
        }
      },
      set (v) {
        this.document = Object.assign({}, this.document, {
          ar24ProviderId: v.ar24ProviderId
        })
      }
    },
    computedMailevaSettings: {
      get () {
        return {
          mailevaProviderId: this.document?.mailevaProviderId
        }
      },
      set (v) {
        this.document = Object.assign({}, this.document, {
          mailevaProviderId: v.mailevaProviderId
        })
      }
    },
    computedEmailSettings: {
      get () {
        return {
          email: this.document?.email,
          outgoingEmailProviderId: this.document?.outgoingEmailProviderId
        }
      },
      set (v) {
        this.document = Object.assign({}, this.document, {
          email: v?.email,
          outgoingEmailProviderId: v.outgoingEmailProviderId
        })
      }
    },
    computedSmsSettings: {
      get () {
        return {
          smsProviderId: this.document?.smsProviderId
        }
      },
      set (v) {
        this.document = Object.assign({}, this.document, {
          smsProviderId: v.smsProviderId
        })
      }
    },
    computedCustomFields: {
      get () {
        const a = []
        a.length = 10
        return this.document?.customFields || a
      },
      set (v) {
        this.document = Object.assign({}, this.document, { customFields: v })
      }
    },
    computedIdentification: {
      get () {
        return {
          id: this.tab?.documentId,
          businessIdentifier: this.document?.businessIdentifier,
          name: this.document?.name,
          canChangeParent: this.document?.canChangeParent ?? this.documentIsNew,
          groupamaCodeAssure: this.document?.groupamaCodeAssure,
          groupamaIsActive: this.document?.groupamaIsActive,
          fax: this.document?.fax,
          importKey: this.document?.importKey,
          isDefaultForImport: this.document?.isDefaultForImport,
          isHeadOffice: this.document?.isHeadOffice,
          phone: this.document?.phone,
          parentId: this.document?.parentId,
          companyId: this.document?.companyId,
          isCompany: this.document?.isCompany
        }
      },
      set (v) {
        this.document = Object.assign({}, this.document, {
          name: v?.name,
          businessIdentifier: v?.businessIdentifier,
          groupamaCodeAssure: v?.groupamaCodeAssure,
          fax: v?.fax,
          importKey: v?.importKey,
          isDefaultForImport: v?.isDefaultForImport,
          phone: v?.phone,
          parentId: v?.parentId,
          companyId: v?.companyId,
          isCompany: v?.isCompany
        })
      }
    },
    cacheType () {
      return DocEdit.CacheType.BusinessDivisionDetail
    },
    id () {
      return this.tab.documentId
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('CompanyStructureSettings')
    },
    isFormValide: {
      get () {
        return this.dataForm
      },
      set (v) {
        this.dataForm = v
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    }
  },
  data () {
    return {
      cascadeDeleteRunning: false,
      showCascadeDeleteConfirm: false,
      dataSettings: null,
      dataForm: false,
      dataLogoFile: null,
      dataIsDefaultForImport: false
    }
  },
  methods: {
    async loadStripeSettings () {
      const r = await this.$http().get('/core/v6/settings/portal')
      this.dataSettings = r?.data
    },
    companyId () {
      return this.tab.documentId
    },
    async refreshDocumentHook () {
      this.dataLogoFile = null
      if (this.documentIsNew) {
        const customFields = []
        customFields.length = 10
        this.document = Object.assign({}, this.document, {
          parentId: null,
          companyId: null,
          isCompany: false,
          name: null,
          email: null,
          businessIdentifier: null,
          groupamaCodeAssure: null,
          groupamaIsActive: false,
          fax: null,
          importKey: null,
          isDefaultForImport: false,
          isHeadOffice: false,
          phone: null,
          logoFileId: null,
          provisionDay: 0,
          customFields: customFields,
          address: {
            address1: null,
            address2: null,
            address3: null,
            city: null,
            country: null,
            postalCode: null,
            stateProvince: null
          }
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }

        this.dataIsDefaultForImport = this.document?.isDefaultForImport

        this.$store.commit('setModule', {
          name: this.document?.name,
          icon: 'i.BusinessDivisions'
        })
      }

      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    async save () {
      if (this.dataLogoFile) {
        const formData = new FormData()
        formData.append('file', this.dataLogoFile)
        const r = await this.$http().post('/file', formData)
        this.document.logoFileId = r?.data
      }

      this.dataLogoFile = null

      const apiUrl = '/core/v6/business-divisions'
      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.push('/business-divisions/' + response.data?.id)
        })
        return response
      } else {
        await this.$http().put(`${apiUrl}/${this.id}`, this.document).then(r => {
        })
      }
    },
    confirmDeleteCascade () {
      this.showCascadeDeleteConfirm = true
    },
    async doCascadeDelete () {
      this.cascadeDeleteRunning = true
      this.$http().delete(`/core/v6/business-divisions/${this.id}`, { data: { force: true } })
        .then(async () => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.CascadeDeleteConfirmation'))
          this.$router.go(-1)
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response?.data?.message)
        })
        .finally(() => {
          this.cascadeDeleteRunning = false
        })
    },
    async deleteDocument () {
      this.$http().delete(`/core/v6/business-divisions/${this.id}`, { data: { force: false } })
        .then(async () => this.$router.go('-1'))
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response?.data?.message)
        })
    }
  },
  created () {
    this.loadStripeSettings()
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.identification-logo
  display grid
  grid-template-areas 'a a a b'

.a
  grid-area a

.b
  grid-area b
</style>
